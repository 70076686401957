<template>
    <div id="app">
        <notifications position="bottom right"/>

        <router-view />
    </div>
</template>

<script>
export default {
    name: "App"
}
</script>
