import { createStore } from 'vuex';
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies();

export default createStore({
  state: {
    isMobile: false,
    isLogged: false
  },
  mutations: {
    setIsMobile(state, payload) {
      state.isMobile = payload;
    },

    setIsLogged(state, payload) {
      state.isLogged = payload;
    },
  },
  actions: {
    checkMobileStatus({ commit }) {
      const userAgent = window.navigator.userAgent;
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
      commit('setIsMobile', isMobile);
    },
    checkLoggedStatus({ commit }) {
      const isLogged = cookies.get('apiToken') != null;
      commit('setIsLogged', isLogged);
    },
  },
});
