import { createI18n } from 'vue-i18n'
import kz from '../locales/kz.json'
import ru from '../locales/ru.json'
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies();

function loadLocaleMessages() {
    const locales = [{ kz: kz }, { ru: ru }]
    const messages = {}
    locales.forEach(lang => {
        const key = Object.keys(lang)
        messages[key] = lang[key]
    })
    return messages
}

export default createI18n({
    locale: cookies.get('locale') ?? 'ru',
    fallbackLocale: cookies.get('locale') ?? 'ru',
    messages: loadLocaleMessages()
})
