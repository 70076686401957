import {
    createRouter,
    createWebHistory,
} from "vue-router";
import routes from "./routes";
import {useCookies} from "vue3-cookies";
import {nextTick} from "vue";

const { cookies } = useCookies();

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 }
    },
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(route => route.meta.requiresAuth)) {
        const cookie = cookies.get('apiToken');
        if (cookie == null) {
            next({name: 'login'})
        }
        else next();
    }
    else next();
});
const DEFAULT_TITLE = '"Бизнес Трамплин"';

router.afterEach((to, ) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
        document.description = 'Национальный конкурс для поддержки малого и микро бизнеса. Ваша возможность выиграть до 7 миллионов тенге на развитие своего бизнеса.'
    });
});

export default router;