import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './boot/store'
import 'bootstrap/dist/css/bootstrap.css'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'
import 'bootstrap/dist/css/bootstrap.css'
import i18n from './boot/i18n'
import {LoadingPlugin} from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'
import Notifications from '@kyvg/vue3-notification'
import VueTheMask from 'vue-the-mask'

const app = createApp(App)

app.use(store)

app.use(bootstrap)

app.use(router)

app.mixin({
    beforeCreate() {
        this.$store.dispatch('checkMobileStatus')
        this.$store.dispatch('checkLoggedStatus')
    }
})

app.use(i18n)

app.use(LoadingPlugin)

app.use(Notifications)

app.use(VueTheMask)

app.mount('#app')

