const routes = [
    {
      path: "/",
      component: () => import("../layouts/MainLayout.vue"),
      children: [
        {
          name: 'homepage',
          path: "/",
          meta: {
            title: '"Бизнес Трамплин"'
          },
          component: () => import("../pages/HomePage.vue")
        },
        {
          name: 'about',
          path: "about",
          meta: {
            title: 'О конкурсе'
          },
          component: () => import("../pages/About.vue")
        },
        {
          name: 'rules',
          path: "rules",
          meta: {
            title: 'Правила'
          },
          component: () => import("../pages/Rules.vue")
        },
        {
          name: 'contacts',
          path: "contacts",
          meta: {
            title: 'Контакты'
          },
          component: () => import("../pages/Contacts.vue")
        },
        // {
        //   path: "auth",
        //   children: [
        //     {
        //       name: 'login',
        //       path: "login",
        //       meta: {
        //         title: 'Логин'
        //       },
        //       component: () => import("../pages/Login.vue")
        //     },
        //     {
        //       name: 'forgot',
        //       path: "forgot-password",
        //       meta: {
        //         title: 'Восстановление пароля'
        //       },
        //       component: () => import("../pages/Forgot-pass.vue")
        //     },
        //     {
        //       name: 'reset',
        //       path: "reset-password",
        //       meta: {
        //         title: 'Создание нового пароля'
        //       },
        //       component: () => import("../pages/New-pass.vue")
        //     },
        //     // {
        //     //   name: 'registration',
        //     //   path: "registration",
        //     //   meta: {
        //     //     title: 'Регистрация'
        //     //   },
        //     //   component: () => import("../pages/Registration_step_1.vue")
        //     // }
        //   ]
        // },
        {
          name: 'terms',
          path: "terms",
          meta: {
            title: 'Условиях участия'
          },
          component: () => import("../pages/Terms.vue")
        },
        {
          name: 'privacy',
          path: "privacy",
          meta: {
            title: 'Политика конфиденциальности'
          },
          component: () => import("../pages/Privacy.vue")
        },
        {
          name: 'results',
          path: "results",
          meta: {
            title: 'Результаты голосования'
          },
          component: () => import("../pages/Results.vue")
        },
      ],
    },
    // {
    //   path: '/user',
    //   meta: { requiresAuth: true },
    //   component: () => import("../layouts/MainLayout.vue"),
    //   children: [
    //     {
    //       name: 'profile',
    //       path: "profile",
    //       meta: {
    //         title: 'Профиль'
    //       },
    //       component: () => import("../pages/Profile.vue")
    //     }
    //   ]
    // },
    // This page does not exist
    {
      path: "/:catchAll(.*)*",
      component: () => import("../layouts/MainLayout.vue"),
      children: [
        {
          name: 'error404',
          path: "/:catchAll(.*)*",
          meta: {
            title: 'Не найдено'
          },
          component: () => import("../pages/Error404.vue")
        },
      ]
    },
  ];

  export default routes;
